import { QueryOptions, useTeamfightQuery } from "~/graphql";
import { RiotPlatformRegion } from "~/types/riot";
import { Summoner, SummonerRankedEntry, SummonerRankedStats } from "~/types/summoner";
import { getSummonerProfileQuery } from "~/graphql/query/getSummonerProfile/getSummonerProfile.query";

export interface SummonerProfile {
  summoner: Summoner;
  summonerRankedEntries: SummonerRankedEntry[];
  summonerRankedStats: SummonerRankedStats[];
  summonerLeaderboardRanking: {
    total: number;
    position: number;
  } | null;
}

export interface GetSummonerProfileResult {
  getSummonerProfile: SummonerProfile | null;
}

interface Variables {
  summonerName?: string;
  puuid?: string;
  region: RiotPlatformRegion;
  isView?: boolean;
}

export async function getSummonerProfile(variables: Variables, options?: QueryOptions) {
  const result = await useTeamfightQuery<GetSummonerProfileResult, Variables>({
    query: getSummonerProfileQuery,
    variables,
    options
  });

  return { ...result, data: computed<SummonerProfile | null>(() => result.result.value?.getSummonerProfile || null) };
}