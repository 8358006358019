<template>
  <div class="summoner-info__wrapper">
    <div class="summoner-info">
      <div class="summoner-info__header">
        <SummonerProfileIcon
            :summoner="summoner"
            borderRadius="0.3rem"
            disableLink
            disableTooltip
            size="6rem"
        />

        <h1 class="summoner-info__summoner-name">
          {{ summoner.gameName }}<span>#{{ summoner.tagLine }}</span>
        </h1>

        <div class="summoner-info__actions-wrapper">
          <div class="summoner-info__actions">
            <div v-if="isClient" class="summoner-info__favourite" @click="toggleFavourite">
              <UIcon
                  :name="!isFavourite ? 'i-heroicons-star' : 'i-heroicons-star-solid'"
                  style="color: #F15D3B; font-size: 2.2rem"
              />
            </div>

            <div class="summoner-info__update">
              <TeamfightButton :disabled="!!isUpdating" icon="i-heroicons-arrow-path" @click="triggerUpdateSummoner">
                {{ isUpdating ? 'Updating...' : 'Update' }}
              </TeamfightButton>
            </div>
          </div>

          <div class="summoner-info__last-updated">
            Last Updated:
            {{
              isUpdating ? 'Updating...' : summoner.lastUpdated ? useGeneralStore().getTimeAgo(summoner.lastUpdated) :
                  'Never'
            }}
          </div>
        </div>
      </div>

      <div class="summoner-info__content">
        <SummonerRanking
            v-if="rankedTFTEntry && summonerLeaderboardRanking"
            :rankedTFTEntry="rankedTFTEntry"
            :summonerLeaderboardRanking="summonerLeaderboardRanking"
        />

        <div v-else class="summoner-info__unranked">
          <div class="summoner-info__unranked-text">
            Unranked
          </div>
          <div class="summoner-info__unranked-icon">
            <TeamfightImage
                :alt="'UNRANKED'"
                :src="getTFTRegaliaIconUrl('UNRANKED')"
                :style="{ height: '100%', width: 'auto' }"
            />
          </div>
        </div>

        <div class="summoner-info__stats">
          <PlacementGrid :summonerRankedStats="summonerRankedStats"/>

          <div class="summoner-info__ranked-stats">
            <div v-for="stat in rankedStats" :class="['summoner-info__ranked-stat', { '--empty': !stat.textValue }]">
              <div class="summoner-info__ranked-stat-text">
                <div class="summoner-info__ranked-stat-title">
                  {{ stat.text }}
                </div>

                <div class="summoner-info__ranked-stat-value">
                  {{ stat.textValue }}
                </div>
              </div>

              <div class="summoner-info__ranked-stat-bar">
                <UProgress :color="stat.color" :value="stat.percentageValue * 100"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { toXDecimalPlaces } from "~/helpers/math";
import { Summoner, SummonerRankedEntry, SummonerRankedStats } from "~/types/summoner";
import { useFavouriteSummoners } from "~/helpers/storage";
import { useGeneralStore } from "~/stores/GeneralStore";
import { getTFTRegaliaIconUrl } from "~/utils/cdn";
import PlacementGrid from "~/components/Summoner/PlacementGrid.vue";
import { isClient } from "@vueuse/shared";

interface Props {
  summoner: Summoner;
  summonerRankedEntries: SummonerRankedEntry[];
  summonerRankedStats: SummonerRankedStats[];
  summonerLeaderboardRanking: {
    total: number;
    position: number;
  } | null;
  updateSummoner: () => Promise<void>;
}

const props = defineProps<Props>();

const favouriteSummoners = useFavouriteSummoners();

const isFavourite = computed(() => favouriteSummoners.value.map(i => i.uuid).includes(props.summoner.uuid));

const rankedTFTEntry = computed(() => props.summonerRankedEntries.find(e => e.queueType === "RANKED_TFT"));

const rankedStats = computed(() => [
  {
    text: "Win Rate",
    textValue: rankedTFTEntry.value?.firsts ? `${toXDecimalPlaces((rankedTFTEntry.value?.firsts / (rankedTFTEntry.value?.wins + rankedTFTEntry.value?.losses)) * 100, 2)}%` : '',
    percentageValue: rankedTFTEntry.value?.firsts ? rankedTFTEntry.value?.firsts / (rankedTFTEntry.value?.wins + rankedTFTEntry.value?.losses) : 0,
    color: "green"
  },
  {
    text: "Top 4 Rate",
    textValue: rankedTFTEntry.value ? `${toXDecimalPlaces((rankedTFTEntry.value?.wins / (rankedTFTEntry.value?.wins + rankedTFTEntry.value?.losses)) * 100, 2)}%` : 0,
    percentageValue: rankedTFTEntry.value ? rankedTFTEntry.value?.wins / (rankedTFTEntry.value?.wins + rankedTFTEntry.value?.losses) : 0,
    color: "blue"
  },
  {
    text: "Played",
    textValue: rankedTFTEntry.value ? rankedTFTEntry.value?.wins + rankedTFTEntry.value?.losses : '',
    percentageValue: 1,
    color: "primary"
  },
  {
    text: "Avg. Placement",
    textValue: rankedTFTEntry.value?.avgPlacement ? `#${toXDecimalPlaces(rankedTFTEntry.value?.avgPlacement || 0, 2)}` : '',
    percentageValue: rankedTFTEntry.value?.avgPlacement ? rankedTFTEntry.value?.avgPlacement / 8 : 0,
    color: "primary"
  }
]);

const toggleFavourite = () => {
  if (favouriteSummoners.value.find(s => s.uuid === props.summoner.uuid)) {
    favouriteSummoners.value = [...favouriteSummoners.value].filter(s => s.uuid !== props.summoner.uuid);
  } else {
    favouriteSummoners.value.push(props.summoner);
  }
};

const isUpdating = ref(false);
const triggerUpdateSummoner = async () => {
  isUpdating.value = true;
  await props.updateSummoner();
  isUpdating.value = false;
};
</script>

<style lang="scss" scoped>
.summoner-info__wrapper {
  display: flex;
  flex-direction: column;
  grid-column: span 6;
  border-radius: var(--BorderRadius-S);
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: linear-gradient(195deg, rgba(30, 41, 71) 0, #141627 100%);
  height: 40rem;

  .summoner-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;

    .summoner-info__unranked {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-weight: var(--FontWeight-Bold);

      .summoner-info__unranked-icon {
        height: 12rem;
        overflow: hidden;
        filter: contrast(0);
      }
    }

    .summoner-info__header {
      display: grid;
      grid-template-columns: 6rem 1fr 16rem;
      gap: 1rem;
      align-items: center;

      .summoner-info__summoner-name {
        font-weight: var(--FontWeight-Black);
        font-size: 3.2rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        span {
          font-size: 1.4rem;
          opacity: 0.6;
        }
      }

      .summoner-info__actions-wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.6rem;

        .summoner-info__actions {
          display: flex;
          justify-content: flex-end;
          gap: 1rem;
          align-items: center;

          .summoner-info__update {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 0.4rem;
          }

          .summoner-info__favourite {
            display: flex;
            align-items: center;
            cursor: pointer;
          }
        }

        .summoner-info__last-updated {
          font-size: 1rem;
          text-align: right;
        }
      }
    }

    .summoner-info__content {
      flex: 1;
      display: grid;
      grid-template-columns: 1fr 2fr;

      .summoner-info__stats {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .summoner-info__ranked-stats {
          flex: 1;
          padding: 1rem;
          position: relative;
          display: grid;
          gap: 1rem;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr 1fr;
          justify-content: center;
          align-items: center;

          .summoner-info__ranked-stat {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            &.--empty {
              opacity: 0.2;
            }

            .summoner-info__ranked-stat-text {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-weight: var(--FontWeight-Bold);
            }
          }
        }
      }
    }
  }
}
</style>