import { gql } from "@apollo/client/core";

export const FRAGMENT_SUMMONER_RANKED_ENTRY = gql`
    fragment FragmentSummonerRankedEntry on SummonerRankedEntry {
        uuid
        summonerUuid
        region
        queueType
        set
        logbook
        summonerId
        wins
        losses
        leagueId
        ratedTier
        ratedRating
        tier
        division
        leaguePoints
        hotStreak
        veteran
        inactive
        freshBlood
        miniSeries
        firsts
        avgPlacement
        updatedAt
        createdAt
    }
`;