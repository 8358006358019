<template>
  <div>
    <div v-if="summonerProfile" class="summoner__tooltip">
      <div class="summoner__tooltip-loader">
        <UProgress v-if="loading || isUpdating" animation="carousel"/>
      </div>

      <SummonerInfo
          :summoner="summonerProfile.summoner"
          :summonerLeaderboardRanking="summonerProfile.summonerLeaderboardRanking"
          :summonerRankedEntries="summonerProfile.summonerRankedEntries"
          :summonerRankedStats="summonerProfile.summonerRankedStats"
          :updateSummoner="triggerUpdateSummoner"
      />
    </div>

    <div v-else-if="!loading" class="summoner__tooltip">
      Error trying to fetch Summoner data.
    </div>
  </div>
</template>

<script lang="ts" setup>
import SummonerInfo from "~/components/Summoner/SummonerInfo.vue";
import { RiotPlatformRegion } from "~/types/riot";
import { getSummonerProfile } from "~/graphql/query/getSummonerProfile/getSummonerProfile";
import { updateSummoner } from "~/graphql/mutation/updateSummoner/updateSummoner";

interface Props {
  puuid: string;
  region: RiotPlatformRegion;
}

const props = defineProps<Props>();

const isUpdating = ref(false);

const { data: summonerProfile, loading, refetch } = await getSummonerProfile({
  puuid: props.puuid,
  region: props.region
}, { prefetch: false });

const triggerUpdateSummoner = async () => {
  const uuid = summonerProfile.value?.summoner.uuid;

  if (uuid) {
    isUpdating.value = true;
    await updateSummoner({ uuid });
    isUpdating.value = false;

    refetch();
  }
};
</script>

<style lang="scss" scoped>
.summoner__tooltip {
  background-color: var(--UI-Secondary);
  border: 1px solid var(--UI-Border);

  .summoner__tooltip-loader {
    height: 0.6rem;
  }
}
</style>