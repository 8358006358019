import { useTeamfightMutation } from "~/graphql";
import { updateSummonerMutation } from "~/graphql/mutation/updateSummoner/updateSummoner.mutation";
import { Summoner } from "~/types/summoner";

interface Return {
  updateSummoner: Summoner;
}

interface Variables {
  uuid: string;
}

export async function updateSummoner(variables: Variables) {
  const result = await useTeamfightMutation<Return, Variables>({ mutation: updateSummonerMutation, variables });
  return { ...result, data: computed(() => result.result?.data?.updateSummoner) };
}