import { gql } from "@apollo/client/core";
import { FRAGMENT_SUMMONER } from "~/graphql/fragments/Summoner.fragment";
import { FRAGMENT_SUMMONER_RANKED_ENTRY } from "~/graphql/fragments/SummonerRankedEntry.fragment";

export const updateSummonerMutation = gql`
    ${FRAGMENT_SUMMONER}
    ${FRAGMENT_SUMMONER_RANKED_ENTRY}

    mutation UpdateSummoner($uuid: String!) {
        updateSummoner(uuid: $uuid) {
            ...FragmentSummoner

            rankedEntries {
                ...FragmentSummonerRankedEntry
            }
        }
    }
`;
