import { gql } from "@apollo/client/core";

export const FRAGMENT_SUMMONER = gql`
    fragment FragmentSummoner on Summoner {
        uuid
        puuid
        summonerId

        gameName
        tagLine

        summonerLevel
        profileIconId
        region

        hidden

        lastUpdated
    }
`;