<template>
  <SummonerTooltip
      :disableTooltip="disableTooltip"
      :puuid="summoner.puuid"
      :region="summoner.region"
      :tooltipDelay="tooltipDelay"
  >
    <div>
      <component :is="wrapperComponent" :style="iconStyle" :to="summonerLink" class="summoner-profile-icon">
        <div v-if="!hideLevel" :style="{ borderRadius }" class="summoner-profile-icon__level">
          {{ summoner.summonerLevel }}
        </div>
        <TeamfightImage
            :alt="`${summoner.gameName}#${summoner.tagLine}`"
            :src="getSummonerProfileIconUrl(summoner.profileIconId)"
        />
      </component>
    </div>
  </SummonerTooltip>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { getSummonerProfileIconUrl } from "~/utils/cdn";
import { getSummonerPageLink } from "~/utils/router";
import { TOOLTIP_DELAY } from "~/utils/constants";
import { Summoner } from "~/types/summoner";

interface Props {
  summoner: Summoner;
  patch?: string;
  size?: string;
  disableLink?: boolean;
  borderRadius?: string;
  tooltipDelay?: number;
  disableTooltip?: boolean;
  hideLevel?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  size: "2.4rem",
  borderRadius: "0",
  tooltipDelay: TOOLTIP_DELAY
});

const wrapperComponent = computed(() => props.disableLink ? 'div' : resolveComponent('NuxtLink') as unknown);

const iconStyle = computed(() => ({
  cursor: props.disableLink && props.disableTooltip ? 'auto' : 'pointer',
  borderRadius: props.borderRadius,
  height: props.size,
  width: props.size,
  pointerEvents: props.disableLink && props.disableTooltip ? 'none' : 'all'
}));

const summonerLink = computed(() => {
  if (props.disableLink) return null;

  return getSummonerPageLink(props.summoner);
});
</script>

<style lang="scss" scoped>
.summoner-profile-icon {
  position: relative;
  display: flex;
  height: 100%;
  overflow: hidden;
  border: 0.3rem solid var(--UI-Primary);

  .summoner-profile-icon__level {
    position: absolute;
    bottom: -0.4rem;
    right: -0.4rem;
    background-color: var(--UI-Primary);
    padding: 0.2rem 0.6rem;
    font-size: 1rem;
    font-weight: var(--FontWeight-Bold);
    z-index: 100;
  }
}
</style>