import { Summoner } from "~/types/summoner";

enum LocaleStorage {
  SEARCH_HISTORY = 'teamfight:search-history',
  FAVOURITE_SUMMONERS = 'teamfight:favourite-summoners'
}

export const useSearchHistory = () => {
  return useLocalStorage<Summoner[]>(LocaleStorage.SEARCH_HISTORY, []);
};

export const useFavouriteSummoners = () => {
  return useLocalStorage<Summoner[]>(LocaleStorage.FAVOURITE_SUMMONERS, []);
};