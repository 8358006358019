import { gql } from "@apollo/client/core";
import { FRAGMENT_SUMMONER } from "~/graphql/fragments/Summoner.fragment";
import { FRAGMENT_SUMMONER_RANKED_ENTRY } from "~/graphql/fragments/SummonerRankedEntry.fragment";

export const getSummonerProfileQuery = gql`
    ${FRAGMENT_SUMMONER}
    ${FRAGMENT_SUMMONER_RANKED_ENTRY}

    query GetSummonerProfile($summonerName: String, $puuid: String, $region: RiotRegion!, $isView: Boolean) {
        getSummonerProfile(summonerName: $summonerName, puuid: $puuid, region: $region, isView: $isView) {
            summoner {
                ...FragmentSummoner
            }

            summonerRankedEntries {
                ...FragmentSummonerRankedEntry
            }

            summonerRankedStats {
                uuid
                summonerUuid
                region
                queueId
                set
                latestPlacements
                itemStats
                championStats
                traitStats
                augmentStats
                updatedAt
                createdAt
            }

            summonerLeaderboardRanking {
                position
                total
            }
        }
    }
`;
